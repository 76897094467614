import slice_1 from '../assets/slice-1.webp'
import slice_2 from '../assets/slice-2.webp'
import { useState, useEffect } from 'react'
import React from 'react'
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const PhotoWall = () =>{
    const beige_bg = '#EDE6DC'
    const [isDesktop, setDesktop]=useState(window.innerWidth >800)
    const updateMedia = () => {
        setDesktop(window.innerWidth > 800);
      };
    
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
      });

    return (
        <div style={{backgroundColor: beige_bg}}>
            {/* <h1 className="text-center fs-3 m-5">Work in progress, check back in a few days!</h1> */}

            <img className='w-100' src={slice_1} alt='photos of the bride and groom'/>
                
            <div className='row mx-auto'>
                <div className='col-md-8 col-sm-10 col-xl-3 p-3 mx-auto'>
                <Carousel className="w-100 mx-auto">
                    {Array.from({ length: 20 }, (_, i) => (
                        <Carousel.Item className='align-center'>
                        <img className="d-block w-100" src={require(`../assets/${i+1}.jpeg`)} alt="First slide" />
                        </Carousel.Item>
                        ))
                    }
                </Carousel>
                <p className='text-secondary'>Taken in Spring, April 2024</p>
                <h4 className='Inter'>Photographed by @youthday_jeju (@fromairplane)</h4>
                <p>
                    <i>Photographer’s Partners</i><br/>
                    Hair & Makeup: @lar_a_hyunseon from @lar_a_hairmakeup<br/>
                    Gown: @lara_dress_jeju<br/>
                    Suit: @horsetailor_<br/>
                    Flower: @two_littleflower
                </p>
                </div>
            </div>

            <img className='w-100' src={slice_2} alt='photos of the bride and groom'/>

            

        </div>
    )
}

export default PhotoWall