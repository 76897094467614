// import { ref } from 'vue'
import FAQs from '../components/FAQ'

const FAQPage= () => {
    
  return (
    <div className="col-md-9 mx-auto">

      <div data-aos="fade-down" data-aos-duration="1000" data-aos-offset="200">
        
        <h3 className='newsreader fs-1 text-center mt-5'>
            <i>Frequently Asked Questions   </i>       
        </h3>
        <p className='fs-5 text-center'>
        Don’t know what to wear? Don’t know where to go? We got you!
        </p>
      <FAQs/ >
      </div>
    </div>
  )
}

export default FAQPage