import QnA from "./QnA";
import ColorRadio from './ColorRadio'


const FAQs = () => {
    return (
        <div className="row m-5">
        <div className="col-12">
            <QnA question="Where is the ceremony location?">
            Raffles Hotel - The Lawn (1 Beach Rd, Singapore 189673).<br/>For taxis, use the North Bridge Road Entrance.<br/><br/>
            Look out for our friendly ushers’ if you need directional assistance to the Lawn.
            </QnA>
            <QnA question="What is the schedule for the day?">
            The wedding ceremony will be followed by a reception with photo opportunities and light refreshments.
            </QnA>
            <QnA question="What’s the wet weather plan?">
            While we are praying and hoping for fair weather, if it does rain before the ceremony begins, we will move the Holy Matrimony session to one of the suites in Raffles Hotel. The hotel staff and our usher friends will be sure to guide you to the right place.
            </QnA>
            <QnA question="What is the dress code? ">
            Dress smart 😎, but dress light 🥵. <br/><br/>
            <u>On style.</u> Sandals are acceptable footwear for ladies, but please avoid flip-flops, crocs and et al. You may come in long-sleeve shirts but it’s fine if you don’t, even our dads are coming in sauve with polo tees.<br/><br/>
            <u>On colour.</u> You may opt for earthy tone for your outfit if you wish to be in theme, but anything within your wardrobe. Do avoid green else you will be blending in with the lawn or white 👀.<br/>
            <ColorRadio/>
            <br/>
            For our international guests, do note that Singapore's weather is warm & humid. In other words, bring a personal portable fan 🌬 to cool yourself!
            </QnA>
            <QnA question="Can I bring a plus one?">
            Due to limited space, seating is reserved for invitees named on the invitations.            
            </QnA>
            <QnA question="Is there parking available?">
            Limited parking is available at Raffles Hotel (first-come, first-served). Please listen for the announcements by our emcees after the ceremony. <br/>
            <br/>You may also choose to park nearby at Raffles City Shopping Centre (5-mins walk).
            </QnA>
            <QnA question="Can we bring gifts to the wedding?">
            We gratefully accept cash gifts but do not be obliged to do so. <br/>
            Red packets are not expected, your presence is our greatest gift!
            </QnA>
            <QnA question="What kind of food will be served?">
            Light bites (canapés) and soft drinks will be served.            
            </QnA>
            <QnA question="Can we take photos or videos during the ceremony?">
            Feel free to take photos and videos during the ceremony and share them with us! <br/><br/>
            To ensure our professional photographers and videographer can capture the ceremony without interruption, kindly be mindful of their positions.
            </QnA>
            <p className="text-center fs-5">Still got questions? You have our numbers, just drop us a text!</p>



        </div>
        </div>
    )

}

export default FAQs;