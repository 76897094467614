import React, { useEffect, useState } from 'react';
import image from '../assets/webhero.webp'
    
    // const apiKey = "XncOca42e44bbSFZoD04f3x9bdLdOPgz6Ai5FOTo"
const key_endpoint ="https://jmmgvqqlg7.execute-api.ap-southeast-2.amazonaws.com/apiKeysForWedding"
const submissionEndpoint = "https://gyyzvyhewh.execute-api.ap-southeast-2.amazonaws.com/rsvp-wedding";
const currentDate = new Date();

  // Get the individual components of the date and time
  const year = currentDate.getFullYear();
  const month = ('0' + (currentDate.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based month
  const day = ('0' + currentDate.getDate()).slice(-2);
  const hours = ('0' + currentDate.getHours()).slice(-2);
  const minutes = ('0' + currentDate.getMinutes()).slice(-2);
  const seconds = ('0' + currentDate.getSeconds()).slice(-2);

  // Construct the timestamp string in the desired format
  const timestampString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

const FormComponent = () => {
  const [guestname, setGuestname] = useState('');
  const [email, setEmail] = useState('');
  const [attending, setAttending] = useState(null);
  const [dietaryReqBool, setDietaryReqBool] = useState(null);
  const [dietaryReqComment, setDietaryReqComment] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const [honeypot, sethoneypot] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const fetchApiKey = async () => {
    try {
      // Fetch API key from API Gateway endpoint
      const response = await fetch(key_endpoint,{
        method: 'GET',
        headers:{
            'Content-Type': 'application/json',
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch API key');
      }
      const data = await response.json();
      let res = JSON.parse(data.body)
      // Store API key in state
      setApiKey(res.api_key);
    } catch (error) {
      console.error('Error fetching API key:', error);
    }
  };

  useEffect(()=>{
    fetchApiKey();
    });

  

  const submitForm = async (event) => {
    event.preventDefault();
    let message = ""

    if(guestname===""){
      message += `Please enter your name<br/>`
    }

    if(email===""){
      message += `Please enter your email<br/>`
    }

    if(attending===null){
      message +="Please indicate your attendance<br/>"
    }

    if(attending===true && dietaryReqBool===null){
      message +="Please indicate your dietary requirements<br/>"
    }

    if(attending && (dietaryReqBool && dietaryReqComment==="")){
      message += `Please indicate your dietary reqquirements`
    }

    if(guestname===""|email===""| (attending===true && dietaryReqBool===null)| attending==null| (attending && (dietaryReqBool && dietaryReqComment===""))){
      setErrorMessage(message)
      console.log("form weird")
      return ""
    }else{
      setErrorMessage("")

    // Perform form submission logic here, such as making an API request
    const formData = {
    "filename": guestname+timestampString+".json",
    "jsonContent":{
        "Name": guestname,
        "Email": email,
        "Attending": attending,
        "DietBool": dietaryReqBool,
        "DietReq": dietaryReqComment
        }
    };

    try {
      // Example: Make a POST request using fetch
      const response = await fetch(submissionEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': apiKey
        },
        body: JSON.stringify(formData)
      });
      let res = await response.json()
      console.log(res.statusCode)

      if (res.statusCode===200) {
        console.log()
        // Form submission successful
        console.log('Form submitted successfully');
        console.log(res)
        setSubmitted(true)
      } else {
        // Form submission failed
        console.error('Form submission failed');
        alert("Oops, something's wrong. Text Po at +65 83837807!")
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }}
  };

  const handleAttendingChange = (event) => {
    setAttending(event.target.value === 'true');
  };

  const handleDietaryReqChange = (event) => {
    setDietaryReqBool(event.target.value === 'true');
  };

  return (
    <div data-aos="fade-down" data-aos-duration="1000" data-aos-offset="200">
    <img 
      src={image} className='img-fluid mx-auto' alt="9th Nov 2024 Janessa & Po's wedding at Raffles Hotel Lawn"></img>
      {/* </div> */}
    <div 
     className="col-md-9 mx-auto">
        <div className='row d-flex justify-content-center'>
          <p className='newsreader fs-1 text-center my-5'>
           Hey there! We are starting a <i>new chapter</i> in our story, <br/>we invite you to join our wedding celebration.
          </p>
        </div>

        <div className='bg-light d-flex justify-content-center p-3'>
          <br/>
          <div className='row fs-5 my-4'>
            <div className='col-8 m-3 mx-auto'>
            <div className='row'>
              <div className='col-sm-3 mb-1 fw-bold'>Date</div>
              <div className='col-sm-9 mb-1'>09 November 2024, Saturday</div>
              <div className='col-sm-3 mb-1 fw-bold'>Time</div>
              <div className='col-sm-9 mb-1'>4pm - 6pm (Please be seated by 4:30pm)</div>
              <div className='col-sm-3 mb-1 fw-bold'>Venue</div>
              <div className='col-sm-9 mb-1'>Raffles Hotel Lawn 🌳</div>
            </div>
            </div>
          </div>
          <br/>
        </div>


        <div
        className='row d-flex justify-content-center my-5'>
          <p className='newsreader fs-2 text-center my-0'> <i>Please RSVP by 30th September 2024</i></p>
          <p className='inter-semibold text-center fs-6 mb-5'> note: This RSVP is for yourself only</p>
        </div>

      <form
      onSubmit={submitForm}>
        <div className="row m-5">
    
    <div className="col-12">
      <div className="form-group">
        <div className="row">
          <label className="col-sm-4 fs-5" htmlFor="guestname">Name</label>
          <div className='col-sm-6'>
            <input
                type="textarea"
                id="guestname"
                value={guestname}
                onChange={(event) => setGuestname(event.target.value)}
                className="form-control border border-dark m-2"
                placeholder="Enter name"
            />
          </div>
        </div>
      </div>
      <div className="form-group">
        <div className="row">
        <label className="col-sm-4 fs-5" htmlFor="email">Mobile Number / Email</label>
        <div className='col-sm-6'>
            <input
            type="text"
            id="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            className="form-control border border-dark m-2"
            placeholder="Enter email"
            />
        </div>
        </div>
      </div>
      
      <div className="form-group">
        <div className='row'>
        <label className="col-sm-4 fs-5">Will you be attending?</label>
        <div className='col-sm-8'>
            <div className="form-check form-check-inline m-2">
                <input
                    className="form-check-input btn-secondary"
                    type="radio"
                    id="attendingYes"
                    value="true"
                    checked={attending === true}
                    onChange={handleAttendingChange}
                />
                
                <label className="form-check-label fs-5 mx-3" htmlFor="attendingYes">Yes</label>
            </div><br/>
            <div className="form-check form-check-inline m-2">
            <input
                className="form-check-input btn-secondary"
                type="radio"
                id="attendingNo"
                value="false"
                checked={attending === false}
                onChange={handleAttendingChange}
            />
            <label className="form-check-label fs-5 mx-3" htmlFor="attendingNo">No</label>
            </div>
        </div>
        </div>
      </div>

      {attending && (
        <div className="form-group">
        <div className='bg-light row'>
            <p className='m-4 fs-5 col-sm-9 mx-auto'>
                Note: There will be no dinner served at our wedding, but we won’t let you leave with an empty stomach!
            </p>

        </div>
        <br/>
        <label className='fs-5'>Do you have any dietary requirements?</label>
            <div className='row m-2'>
                <div className='col-sm-2'>
                    <input
                    className="form-check-input form-check-inline btn-secondary m-2"
                    type="radio"
                    id="dietaryReqNo"
                    value="false"
                    checked={dietaryReqBool === false}
                    onChange={handleDietaryReqChange}
                    />
                    <label className="form-check-label fs-5 mx-3" htmlFor="dietaryReqNo">No</label>
                </div>
                
            </div>
            
            <div className='row m-2'>
                <div className='col-sm-2'>
                    <input
                    className="form-check-input form-check-inline btn-secondary m-2"
                    type="radio"
                    id="dietaryReqYes"
                    value="true"
                    checked={dietaryReqBool === true}
                    onChange={handleDietaryReqChange}
                    />
                    <label className="form-check-label fs-5 mx-3" htmlFor="dietaryReqYes">Yes</label>
                </div>
                <div className="col-sm-7">
                    <input type="text"
                    className="form-control border border-dark"
                    value={dietaryReqComment}
                    onChange={(event) => setDietaryReqComment(event.target.value)}
                    placeholder="If yes, please specify"
                    />
                    </div>
                </div>
                <div className="" hidden>
                <input type="text"
                    className="form-control border border-dark"
                    value={honeypot}
                    onChange={(event) => sethoneypot(event.target.value)}
                    placeholder="You shouldn't be seeing this"
                    />
                
                </div>
                
          </div>
        
      )}
      
    </div>
      
    </div>
    <div className='row'>
    
    <div className="text-center">
      <div className='alert alert-warning m-2' hidden={errorMessage===""}>
        <p className='fs-5 text-center' dangerouslySetInnerHTML={{ __html: errorMessage }}></p>
      </div>
        <button className="btn btn-light rounded-pill mx-auto px-4 fs-5 fw-light mb-4" type="submit" disabled={!(honeypot==='')}>SUBMIT</button>
    </div>
    


    
    {submitted &&(
        <div className='alert alert-success m-4'>
            <p className='text-center fs-4'>
            Your response has been recorded<br/>
            </p>
            {
              attending &&(
                <p className='text-center fs-4'>
                  See you in November!
                </p>
              )
            }
            {
              !attending &&(
                <p className='text-center fs-4'>
                  Aww… we would’ve loved having you here with us. But fret not, and feel free to reach out to us to hang out in the future~
                </p>
              )
            }
        </div>
    )}
    </div>
    </form>
    <br/>
    <p className='text-center fs-6'> If there’s any issue with the form submission, please drop Po or Janessa a text!</p>
    </div>
    </div>
  );
};

export default FormComponent;
