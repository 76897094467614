import React from 'react';

const QnA = ({ question, children }) => {
  return (
    <div className='py-3'>
      <h5 className='inter-semibold'>{question}</h5>
      <p className="fs-5 mt-2 mb-4">{children}</p>
    </div>
  );
};

export default QnA;
