
import logo192 from './assets/logo192.png'
import { useState } from 'react';
import {Outlet, Link} from 'react-router-dom';

const logoStyle = {
    width:'100px'
  }

const PhotoWallStyle = {
    backgroundColor:"#EDE6DC"
}


const Layout = ()=>{
    function handleNavClick(destinationPage){
        setSelected(destinationPage);
      }
    
      var [selected, setSelected] = useState("Form")
    
      return (
        <div className="App" >
          <main className='inter row align-items-center'>
    
              {/* TOP NAV BAR */}
            <div className='row' id="navbar">
                <Link to="/" type="button" onClick={()=>handleNavClick("Form")} className='links d-flex flex-row col-md-5 mx-4'>
                    <img className='m-3' src={logo192} alt="Po Chien and Janesssa" style={logoStyle}/>
                    <p className='my-auto fs-5'><b>Po Chien & Janessa</b></p>
                </Link>
                
                <div className='d-flex flex-row-reverse col-md-6 my-auto'>
                    <Link to='/' type="button" onClick={()=>handleNavClick("Form")} className='button links text-dark border-0 p-3 mx-2 nav-button fs-6 '>{(selected==="Form" ?<p className='text-center my-auto border-bottom border-dark'>RSVP</p>:<p className='text-center my-auto'>RSVP</p>)}</Link>
                    <Link to='/FAQ' type="button" onClick={()=>handleNavClick("FAQ")} className='button links text-dark border-0 p-3 mx-2 nav-button fs-6 '>{(selected==="FAQ" ?<p className='text-center my-auto border-bottom border-dark'>FAQ</p>:<p className='text-center my-auto'>FAQ</p>)}</Link>
                    <Link to='/photos' type="button" onClick={()=>handleNavClick("Photo")} className='button links text-dark border-0 p-3 mx-2 nav-button fs-6 '>{(selected==="Photo" ?<p className='text-center my-auto border-bottom border-dark'>PHOTO GALLERY</p>:<p className='text-center my-auto'>PHOTO GALLERY</p>)}</Link>
                </div>
            </div>
      
        <div className='row' style={selected==="Photo"? PhotoWallStyle: null}>
            <Outlet/>
        </div>
    
            
        </main>
        </div>
        
    
      );
}

export default Layout;