import {useState} from 'react'

const ColorRadio = ()=>{
  const [color, setColor]=useState("Click any of these circles")

  function handleClick(new_color){
    setColor(new_color)
  }

  return(
    <span>
        <span className='row d-flex justify-content-center my-3 newsreader'>
          <span className='col-md-10 newreader my-3'>Color: {color}</span>
          <span className='col-md-4 my-3'>
          <i>linen & the timeless duo</i>
          </span>
          <span className='col-md-6'>
            <button id="button-linen" type="button" className="rounded-circle color-radio" onClick={()=>{handleClick('linen')}} > </button>
            <button id="button-offwhite" type="button" className="rounded-circle color-radio" onClick={()=>{handleClick('off-white')}} > </button>
            <button id="button-black" type="button" className="rounded-circle color-radio" onClick={()=>{handleClick('black')}} > </button>
          </span>
          <span className='col-md-4 my-3'>
          <i>for the adventurous ones</i>
          </span>
          <span className='col-md-6'>
            <button id="button-mustard" type="button" className="color-radio" onClick={()=>{handleClick('mustard')}} > </button>
            <button id="button-skyblue" type="button" className="color-radio" onClick={()=>{handleClick('sky blue')}} > </button>
            <button id="button-taupe" type="button" className="color-radio" onClick={()=>{handleClick('taupe')}} > </button>
            <button id="button-terracotta" type="button" className="color-radio" onClick={()=>{handleClick('terracotta')}} > </button>
            <button id="button-brown" type="button" className="color-radio" onClick={()=>{handleClick('brown')}} > </button>
          </span>
        </span>
      </span>
  )

  }

export default ColorRadio